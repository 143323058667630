/* eslint-disable react/prop-types */
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import useDisbursementShowQuery from 'react/member/components/dashboard/clients/hooks/useDisbursementShowQuery';
import useTrustBeneficiaryShowQuery from 'react/member/components/dashboard/clients/hooks/useTrustBeneficiaryShowQuery';
import DisbursementDecisions from 'react/member/components/dashboard/disbursements/filter_table/disbursement_decisions/DisbursementDecisions';
import DisbursementCategoryAmounts from 'react/member/components/dashboard/disbursements/filter_table/disbursements_category_amounts/DisbursementCategoryAmounts';
import { grey4 } from 'react/shared/theme/palette';

const mapPaymentMethod = [
  { label: 'True Link Card', id: 'Card' },
  { label: 'Electronic Funds Transfer', id: 'EFT' },
  { label: 'Check (True Link Fulfills)', id: 'Check' },
  { label: 'Check (Generate PDF)', id: 'External Check' },
  {
    label: 'Direct Debit',
    id: 'Direct Debit',
  },
  {
    label: 'Wire',
    id: 'Wire',
  },
  {
    label: 'Exchange',
    id: 'Exchange',
  },
];

const useStyles = makeStyles(() => ({
  newFormField: {
    minHeight: '31px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  newFormLabel: {
    width: '220px',
    lineHeight: '1.2em',
    paddingTop: '9px',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  newFormData: {
    width: 'calc(100% - 220px)',
    fontSize: '18px',
    lineHeight: '1.2em',
  },
}));

export default function DetailView({ clientId, disbursementId }) {
  const printRoute = `${RailsRoutes.dashboard_organization_disbursement_path(
    clientId,
    disbursementId,
  )}.pdf`;
  const classes = useStyles();
  const { data: { data, disbursementCategoryAmounts, decisions } = {}, isLoading } =
    useDisbursementShowQuery({
      disbursementId,
    });

  const { data: trustBeneData } = useTrustBeneficiaryShowQuery({
    clientSlug: clientId,
  });

  const paymentMethodLabel = mapPaymentMethod.find(
    (method) => method.id === data?.attributes.paymentMethod,
  )?.label;

  return (
    <>
      <tr
        style={{
          borderBottom: `1px solid ${grey4}`,
          backgroundColor: 'whitesmoke',
        }}
      >
        <td colSpan={6}>
          <Typography fontWeight="500">Disbursement Details</Typography>
        </td>
        <td colSpan={1}>
          <a href={printRoute} rel="noreferrer" target="_blank">
            Print
          </a>
        </td>
      </tr>
      {!isLoading ? (
        <>
          <tr style={{ borderBottom: '1px solid lightgrey' }}>
            <td colSpan={6}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <Box className={classes.newFormField}>
                    <Box className={classes.newFormLabel}>Payment Type:</Box>{' '}
                    <Box className={classes.newFormData}>{paymentMethodLabel}</Box>
                  </Box>
                  <Box className={classes.newFormField}>
                    <Box className={classes.newFormLabel}>Payee:</Box>{' '}
                    <Box className={classes.newFormData}>{data.attributes.payeeName}</Box>
                  </Box>
                  {data.attributes.payeeAddress && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>Payee Address:</Box>{' '}
                      <Box className={classes.newFormData}>
                        {`${data.attributes.payeeAddress.street1 || ''} ${
                          data.attributes.payeeAddress.street2 || ''
                        }`}
                        {'  '}
                        {` ${data.attributes.payeeAddress.city || ''}, ${
                          data.attributes.payeeAddress.state || ''
                        } ${data.attributes.payeeAddress.zip || ''}`}
                      </Box>
                    </Box>
                  )}
                  {data.attributes.payeeMemo && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>Memo:</Box>{' '}
                      <Box className={classes.newFormData}>{data.attributes.payeeMemo}</Box>
                    </Box>
                  )}
                  {data.attributes.payeeDisposition && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>Disposition:</Box>{' '}
                      <Box className={classes.newFormData}>{data.attributes.payeeDisposition}</Box>
                    </Box>
                  )}
                  {data.attributes.requestorDescription && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>Requestor Description:</Box>
                      <Box className={classes.newFormData}>
                        {' '}
                        {data.attributes.requestorDescription}
                      </Box>
                    </Box>
                  )}

                  {data.attributes.payeeSwiftCode && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>SWIFT Code:</Box>
                      <Box className={classes.newFormData}> {data.attributes.payeeSwiftCode}</Box>
                    </Box>
                  )}
                  {data.attributes.payeeBankAccount && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>
                        <span>Payee Bank</span>
                        <br />
                        <span>Information:</span>
                      </Box>
                      <Box className={classes.newFormData}>
                        {' '}
                        {data.attributes.payeeBankAccount?.routingNumber && (
                          <span>Routing #: {data.attributes.payeeBankAccount?.routingNumber}</span>
                        )}
                        <br />
                        {data.attributes.payeeBankAccount?.accountNumber && (
                          <span>Account #: {data.attributes.payeeBankAccount?.accountNumber}</span>
                        )}
                      </Box>
                    </Box>
                  )}
                  {data.attributes.payeeSpecialInstructions && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>Special Instructions:</Box>
                      <Box className={classes.newFormData}>
                        {' '}
                        {data.attributes.payeeSpecialInstructions}
                      </Box>
                    </Box>
                  )}
                  {/* https://truelink.atlassian.net/browse/TRUST-5071
                  Temporary hide untill the data is corrected
                  {sourceBankAccount && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>Payment From:</Box>
                      <Box className={classes.newFormData}>
                        {' '}
                        {sourceBankAccount.attributes.nickname ||
                          sourceBankAccount.attributes.bankName}
                        {' -'}
                        {sourceBankAccount.attributes.lastFour}
                      </Box>
                    </Box>
                  )} */}
                  {data.attributes.checkNum && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>Check Number:</Box>
                      <Box className={classes.newFormData}> {data.attributes.checkNum}</Box>
                    </Box>
                  )}
                  {data.attributes.checkMemoLine && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>Check Memo Line:</Box>
                      <Box className={classes.newFormData}> {data.attributes.checkMemoLine}</Box>
                    </Box>
                  )}
                  {data.attributes.postageType && (
                    <Box className={classes.newFormField}>
                      <Box className={classes.newFormLabel}>Postage Type:</Box>
                      <Box className={classes.newFormData}> {data.attributes.postageType}</Box>
                    </Box>
                  )}
                </div>
                <div style={{ width: '50%' }}>
                  {trustBeneData?.govtBenefitsResults.map((benefit) => (
                    <Box className={classes.newFormField} key={benefit.name}>
                      <Box className={classes.newFormLabel}>{benefit.name}: </Box>
                      <Box className={classes.newFormData}>{benefit.value}</Box>
                    </Box>
                  ))}
                </div>
              </div>
            </td>
          </tr>
          <DisbursementCategoryAmounts
            allowInsert={data.attributes.paymentMethod === 'Check'}
            canAddAttachments={data.meta.canAddAttachments}
            checkInsertAttachmentId={data.relationships.smartpayablesInsert?.data?.id}
            disbursementCategoryAmounts={disbursementCategoryAmounts}
            disbursementId={data.id}
          />
          <DisbursementDecisions decisions={decisions} disbursementId={data.attributes.id} />
        </>
      ) : (
        <tr>
          <td colSpan={6}>
            <Box display="flex" justifyContent="center">
              <LinearProgress />
            </Box>
          </td>
        </tr>
      )}
    </>
  );
}
